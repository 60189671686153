import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import { updateLang, updateLangs, updatePageType } from "../store/actions/base";
import { Content404 } from "../components/Content404";
import { HeaderBlock } from "../components/HeaderBlock";
import { FooterBlock } from "../components/FooterBlock";
import { GlobalStyle } from "../components/Layout/GlobalStyles";
import { HelmetBlock } from "../components/HelmetBlock";

const langPrefix = () => {
  const urlLang =
    typeof window !== `undefined` ? window.location.pathname.slice(1, 3) : "";

  return {
    lang: urlLang.match(/en|kz/) ? urlLang : "ru",
    langs: [
      {
        langCode: "ru",
        name: "Русский",
        link: null,
      },
      {
        langCode: "en",
        name: "English",
        link: null,
      },
      {
        langCode: "kz",
        name: "Kazakh",
        link: null,
      },
    ],
  };
};

export default () => {
  const dispatch = useDispatch();
  const { lang, langs } = langPrefix();
  const data = {
    wordpress_id: null,
    yoast: {
      title: "Page not found",
    },
    title: "Page not found",
  };

  useEffect(() => {
    updateLang(dispatch, lang);
    updateLangs(dispatch, langs);
    updatePageType(dispatch, {
      isPageMain: false,
      isPageError: true,
    });
  }, []);

  return (
    <StyledErrorPage>
      <GlobalStyle />
      <HelmetBlock data={data} />
      <HeaderBlock />
      <Content404 />
      <FooterBlock />
    </StyledErrorPage>
  );
};
const StyledErrorPage = styled.div`
  background-color: #f4f7fc;
`;
