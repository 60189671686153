import React, { useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import { translator } from "../../helpers/lang";
import { getRandomInt } from "../../helpers/base";
import { BREAKPOINTS } from "../../settings";
import { useSelector } from "react-redux";

export const Content404 = () => {
  const dataBase = useSelector(state => state.base);

  const {
    lang: { current: langCurrent },
  } = dataBase;

  const svg_404 = `<svg xmlns="http://www.w3.org/2000/svg" 
style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
viewBox="0 0 499301 206412">
  <path d="M164165 147721l-33740 0 0 54722 -37143 0 0 -54722 -93282 0 0 -22115 97535 -123054 32890 0 0 114264 33740 0 0 30905zm-70883 -71734l2268 -22966 -1134 0 -10207 19280 -31472 36576 -15878 10491 19847 -2552 36576 0 0 -40829zm80240 26936c0,-33835 6474,-59448 19422,-76838 12948,-17390 31519,-26085 55714,-26085 25896,0 44940,8554 57132,25660 12192,17107 18288,42861 18288,77263 0,33646 -6521,59306 -19564,76979 -13042,17674 -31661,26510 -55856,26510 -12853,0 -24006,-2410 -33457,-7230 -9451,-4820 -17248,-11719 -23391,-20698 -6143,-8978 -10727,-19847 -13751,-32606 -3025,-12759 -4537,-27077 -4537,-42955zm40829 0c0,21737 2646,38891 7939,51461 5292,12570 14082,18855 26368,18855 12476,0 21360,-5671 26652,-17012 5293,-11341 7939,-29109 7939,-53304 0,-10774 -614,-20462 -1843,-29062 -1228,-8601 -3166,-15925 -5812,-21974 -2646,-6049 -6191,-10680 -10633,-13893 -4442,-3214 -9876,-4820 -16303,-4820 -12286,0 -21076,5907 -26368,17721 -5293,11813 -7939,29156 -7939,52028zm284950 44798l-33740 0 0 54722 -37143 0 0 -54722 -93282 0 0 -22115 97535 -123054 32890 0 0 114264 33740 0 0 30905zm-70883 -71734l2268 -22966 -1134 0 -10207 19280 -31472 36576 -15878 10491 19847 -2552 36576 0 0 -40829z"/>
</svg>`;

  const fills = ["#fff", "#FCFCFC", "#FAFAFA", "#FAFBFF", "#F7F9FF"];

  const [fillIndex, setFillIndex] = useState(0);
  const changeFill = e => setFillIndex(getRandomInt(0, fills.length));

  return (
    <StyledContent404>
      <div
        className="error-404_svg"
        style={{
          fill: fills[fillIndex],
        }}
        dangerouslySetInnerHTML={{
          __html: svg_404,
        }}
      />

      <div className="error-404_content">
        <h1
          dangerouslySetInnerHTML={{
            __html: translator(langCurrent, {
              ru: "Страница <br/>не найдена",
              en: "Page <br/>not found",
              kz: "Бет <br/>табылмады",
            }),
          }}
        />

        <Link
          className="btn"
          to={translator(langCurrent, {
            ru: "/",
            en: "/en/",
            kz: "/kz/",
          })}
          dangerouslySetInnerHTML={{
            __html: translator(langCurrent, {
              ru: "Вернуться на главную",
              en: "Go back home",
              kz: "Басты бетке оралу",
            }),
          }}
          onMouseOver={changeFill}
        />
      </div>
    </StyledContent404>
  );
};

const StyledContent404 = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f4f7fc;
  position: relative;
  box-sizing: border-box;
  min-height: 100vh;
  @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
  }
  .error-404_svg {
    position: relative;
    top: 35vw;
    @media (min-width: ${BREAKPOINTS.mobile + "px"}) {
      top: 20vw;
    }
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      position: absolute;
      left: 0;
      top: 10vw;
    }
    svg {
      display: block;
      width: 100vw;
      height: auto;
      filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.1));
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        width: 50vw;
      }
    }
  }
  .error-404_content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 45vw;
    position: relative;
    z-index: 2;
    @media (min-width: ${BREAKPOINTS.mobile + "px"}) {
      top: 25vw;
    }

    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      width: 40vw;
      margin-left: auto;
      top: 10vw;
      display: block;
    }
    h1 {
      padding-top: 10vw;
      margin-bottom: 52px;
      font-size: 36px;
      color: #0088f6;
      text-align: center;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        text-align: left;
      }
      br {
        display: none;
        @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
          display: block;
        }
      }
    }
    .btn {
      width: fit-content;
      max-width: 300px;
      height: 55px;
      background-color: #0088f6;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      padding: 0 20px;
      margin: 0;
      font-size: 18px;
      border-radius: 7px;
      &:hover {
        opacity: 0.8;
      }
    }
  }
`;
